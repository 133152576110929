<template>
  <!-- 验机报告title -->
  <h3 class="title">{{ title }}</h3>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    }
  }
}
</script>
<style scoped lang="scss">
.title {
  font-size: 14px;
  padding-left: 5px;
  position: relative;
  font-weight: 700;
  color: #111;
  margin-bottom: 20px;

  &:after {
    content: "";
    width: 2px;
    height: 14px;
    background: #4C84FF;
    border-radius: 1px;
    position: absolute;
    left: 0%;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
