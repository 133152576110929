<template>
  <div class="remak_box">
    <!-- 下单备注 -->
    <div class="remark">
      <div class="remark-title">下单备注：</div>
      <div class="remark-cont">{{ remark || '--' }}</div>
    </div>
    <!-- 补拍备注 -->
    <div class="remark">
      <div class="remark-title">补拍备注：</div>
      <div class="remark-cont">{{ againPhotoRemark || '--' }}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    remark: {
      type: String,
      default: ''
    },
    againPhotoRemark: {
      type: String,
      default: ''
    },
  }
}
</script>
<style lang="scss" scoped>
.remak_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: wrap;

  .remark {
    min-width: 48%;
    width: 48%;
    max-width: 48%;

    .remark-title {
      font-size: 14px;
      font-family: FZLanTingHeiS-R-GB;
      font-weight: 400;
      color: #666666;
      margin-bottom: 10px;
    }

    .remark-cont {
      padding: 16px 10px;
      box-sizing: border-box;
      width: 100%;
      height: 130px;
      background: #F9FBFD;
      border: 1px solid #C1D1FF;
      border-radius: 4px;
      font-size: 12px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      color: #666666;
    }
  }
}
</style>