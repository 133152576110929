<template>
  <!--  验机报告表-->
  <div class="box">
    <el-table
              size="small"
              :data="tableData"
              :header-cell-style="{background:'#fff'}"
    >
      <el-table-column
          label="序号"
          align="center"
          class-name="one-style"
          type="index"
      />
      <el-table-column
          label="检测项"
          align="center"
          prop="label"
      />
      <el-table-column
          label="验机类型"
          align="center"
          prop="detectionType"
      >
      <template slot-scope="{row}">
        <span>{{ row.detectionType=='01'?'人工验机':'系统验机'}}</span>
        <span @click="canView(row)" style="cursor: pointer;" v-if="row.key=='1000004'&&(row.cameraTestImgFront||row.cameraTestImgRear)" class="un_tetx">查看拍摄图片</span>
      </template>
      </el-table-column>
      <el-table-column
          label="检测结果"
          align="center"
          prop="value"
          v-slot="{row}"
      >
      <p v-if="(externalorderType=='lb'||externalorderType=='lb2')&&row.label!=='是否正常开机'" :style="{color: row.isNormal?'#000000':'#FF0000'}">{{row.isNormal?'正常':'异常'}}</p>
        <p v-else :style="{color: row.isNormal?'#000000':'#FF0000'}">{{row.value}}</p>
      </el-table-column>
    </el-table>
    <ElImageViewer
        :zIndex="99999"
        v-if="showViewer"
        :on-close="()=>showViewer = false"
        :url-list="photoArr"
    />
  </div>
</template>
<script>
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
export default {
  components: {ElImageViewer},
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    externalorderType:{
      type:String,
      default:""
    }
  },
  data(){
    return{
      photoArr:[],
      showViewer:false
    }
  },
  methods:{
    canView(data){
      this.photoArr=[]
      if(data.cameraTestImgRear){
        this.photoArr.push(data.cameraTestImgRear)  
      }
      if(data.cameraTestImgFront){
        this.photoArr.push(data.cameraTestImgFront,)  
      }
      this.showViewer=true
    }
  }
}
</script>
<style scoped lang="scss">
.box {
  border: 1px solid #E4ECFD;
  /deep/ .el-table .el-table__header th{
    border-right:0;
  }
  .un_tetx{
    color: #0981FE;
     text-decoration: underline;
     margin-left: 10px;
  }
}
</style>
